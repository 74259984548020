<template>
  <div class="page-404">
    <van-empty image="error" description="404~页面找不到了" >
      <van-button @click="navBack" round type="danger" color="#ff8506" class="bottom-button">返 回</van-button>
    </van-empty>
  </div>
</template>
<script>
export default {
  methods: {
    navBack() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.bottom-button {
  width: 120px;
}
</style>
